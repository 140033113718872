import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from '@components/Avatar';
import { useGetFarmers } from '@features/farmer/listFarmers/useGetFarmers';
import { AvatarGroup, Tooltip } from '@mui/material';
import { FARMER_STATUS } from '@utils/constants';
import { getAvatarInitials } from '@utils/string';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarmersTableStyles } from './FarmersTable.style';
import { useAppDispatch, useAppSelector } from '@hooks';
import { changeCount, selectFarmersListPage, selectFarmersListCount, selectFarmersListHasFetchedAll, } from '@features/farmer/listFarmers/farmersList.slice';
export const PAGE_SIZE = 100;
export const useFarmersTableData = () => {
    const { t } = useTranslation();
    const { classes, cx } = useFarmersTableStyles();
    const { data: rows, count: fetchedCount, hasMore, isLoading, isFetching } = useGetFarmers({ shouldFilter: true });
    const dispatch = useAppDispatch();
    const page = useAppSelector(selectFarmersListPage);
    const tableCount = page * PAGE_SIZE;
    const currentResultsCount = useAppSelector(selectFarmersListCount);
    const hasFetchedAll = useAppSelector(selectFarmersListHasFetchedAll);
    // Keep track of the amount of already fetched farmers
    useEffect(() => {
        if (isFetching || fetchedCount === undefined || hasMore === undefined)
            return;
        const noResults = fetchedCount === 0 && currentResultsCount === 0 && !hasMore;
        const updatedCount = currentResultsCount + fetchedCount;
        const computedHasFetchedAll = !hasFetchedAll && !hasMore;
        if (noResults) {
            dispatch(changeCount({ count: 0, hasFetchedAll: true }));
            return;
        }
        if (currentResultsCount <= tableCount) {
            dispatch(changeCount({ count: updatedCount, hasFetchedAll: computedHasFetchedAll }));
        }
    }, [fetchedCount, currentResultsCount, tableCount, dispatch, isFetching, hasMore, hasFetchedAll]);
    const getRowId = (row) => ((row === null || row === void 0 ? void 0 : row.user_id) ? Number(row === null || row === void 0 ? void 0 : row.user_id) : Number(row === null || row === void 0 ? void 0 : row.invitation_id));
    const renderCellStylesByStatus = (params) => {
        const shouldApplyPendingStyles = params.row.status === FARMER_STATUS.PENDING;
        const shouldApplyRefusedStyles = params.row.status === FARMER_STATUS.INVITATION_REFUSED;
        return (_jsx("span", Object.assign({ className: cx(classes.row, shouldApplyPendingStyles && classes.pending, shouldApplyRefusedStyles && classes.refused) }, { children: params.value })));
    };
    const sharedColumnProps = useMemo(() => ({
        sortable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
    }), []);
    const columns = useMemo(() => [
        Object.assign(Object.assign({ field: 'full_name', headerName: t('teams.grid.columns.full-name'), minWidth: 250 }, sharedColumnProps), { valueGetter: ({ row }) => row.first_name && row.last_name ? `${row.first_name} ${row.last_name}` : '-', renderCell: renderCellStylesByStatus }),
        Object.assign(Object.assign({ field: 'email', headerName: t('teams.grid.columns.email'), width: 250 }, sharedColumnProps), { valueGetter: ({ row }) => row.email, renderCell: (params) => {
                return (_jsx(Tooltip, Object.assign({ title: params.value, placement: "right", arrow: true }, { children: renderCellStylesByStatus(params) })));
            } }),
        Object.assign(Object.assign({ field: 'cohort', headerName: t('teams.grid.columns.cohort'), minWidth: 150 }, sharedColumnProps), { valueGetter: ({ row }) => row.cohort && t(`farmers.cohort.${row.cohort}`), renderCell: renderCellStylesByStatus }),
        Object.assign(Object.assign({ field: 'contact', headerName: t('teams.grid.columns.contact'), minWidth: 100 }, sharedColumnProps), { valueGetter: ({ row }) => row.assigned_to, renderCell: (params) => {
                var _a;
                return (_jsx(AvatarGroup, Object.assign({ className: classes.avatarGroup, max: 3 }, { children: (_a = params.value) === null || _a === void 0 ? void 0 : _a.map((contact) => (_jsx(Avatar, Object.assign({ className: classes.avatar, alt: `${contact.first_name} ${contact.last_name}` }, { children: _jsx(Tooltip, Object.assign({ title: contact.email, placement: "top", arrow: true }, { children: _jsx("div", { children: getAvatarInitials(contact.first_name, contact.last_name) }) })) }), Number(contact.id)))) })));
            } }),
        Object.assign(Object.assign({ field: 'status', headerName: t('teams.grid.columns.status'), minWidth: 300 }, sharedColumnProps), { valueGetter: ({ row }) => t(`statuses.${row.status}`), renderCell: renderCellStylesByStatus }),
    ], [t, sharedColumnProps]);
    return { columns, rows, count: currentResultsCount, hasFetchedAll, getRowId, isLoading, isFetching };
};
