import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useCollapsableStyles from '@features/farmer/profile/components/FarmerDashboard/Collapsable.style';
import { DashboardStep } from '@features/farmer/profile/components/FarmerDashboard/Step/DashboardStep';
import { ConfirmEncodingModal } from '@features/farmer/profile/confirmEncoding/ConfirmEncodingModal';
import { OPERATIONAL_DATA_STATUSES, useOperationalDataStatus, } from '@features/farmer/profile/farmSeasonStatus/OperationalDataStatus/useOperationalDataStatus';
import { useToggle } from '@hooks/useToggle';
import CheckCircle from '@mui/icons-material/CheckCircle';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfirmEncodingYoYModal } from '../../confirmEncodingYoY/ConfirmEncodingYoYModal';
import { CARBON_RESULT_MODEL } from '@utils/constants';
export const OPERATIONAL_DATA_TEST_ID = {
    NOT_STARTED: 'operational-data-not-started',
    IN_PROGRESS: 'operational-data-in-progress',
    DONE: 'operational-data-done',
};
export const OperationalDataStatus = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { t } = useTranslation();
    const { classes } = useCollapsableStyles();
    const { operationalDataStatus, canValidateData, validationPerformedBy, isV3ResultsCalculationOngoing, canConfirmAndComputeResults, } = useOperationalDataStatus(props);
    const isBaseline = props.farmSeason.baseline_farm_season_id === null;
    const { open, handleOpen, handleClose } = useToggle();
    return (_jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: operationalDataStatus.status === OPERATIONAL_DATA_STATUSES.IN_PROGRESS ? (_jsx(PeopleOutlineRoundedIcon, { "data-testid": OPERATIONAL_DATA_TEST_ID.IN_PROGRESS, className: classes.icon })) : operationalDataStatus.status === OPERATIONAL_DATA_STATUSES.NOT_STARTED ? (_jsx(PeopleOutlineRoundedIcon, { "data-testid": OPERATIONAL_DATA_TEST_ID.NOT_STARTED, className: classes.greyIcon })) : (_jsx(CheckCircle, { "data-testid": OPERATIONAL_DATA_TEST_ID.DONE, className: classes.checkIcon })), stepStatus: operationalDataStatus, by: validationPerformedBy }), _jsx(Box, Object.assign({ className: classes.container }, { children: canValidateData && (_jsx(Button, Object.assign({ disabled: isV3ResultsCalculationOngoing || !canConfirmAndComputeResults, onClick: handleOpen }, { children: t('farmers.results.compute') }))) })), _jsx(Box, { className: classes.stepConnector }), !!((_b = (_a = props.profile) === null || _a === void 0 ? void 0 : _a.farm_user) === null || _b === void 0 ? void 0 : _b.farm) &&
                props.carbonModel &&
                // Dndc YoY has a specific modal to validate data
                (props.carbonModel === CARBON_RESULT_MODEL.DNDC && !isBaseline ? (_jsx(ConfirmEncodingYoYModal, { farmSeason: props.farmSeason, farmerName: `${(_c = props.profile) === null || _c === void 0 ? void 0 : _c.first_name} ${(_d = props.profile) === null || _d === void 0 ? void 0 : _d.last_name}`, farm: (_f = (_e = props.profile) === null || _e === void 0 ? void 0 : _e.farm_user) === null || _f === void 0 ? void 0 : _f.farm, carbonModel: props.carbonModel, open: open, onClose: handleClose })) : (_jsx(ConfirmEncodingModal, { farmSeason: props.farmSeason, farmerName: `${(_g = props.profile) === null || _g === void 0 ? void 0 : _g.first_name} ${(_h = props.profile) === null || _h === void 0 ? void 0 : _h.last_name}`, farm: (_k = (_j = props.profile) === null || _j === void 0 ? void 0 : _j.farm_user) === null || _k === void 0 ? void 0 : _k.farm, carbonModel: props.carbonModel, open: open, onClose: handleClose })))] }));
};
