import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Typography } from '@mui/material';
import { NewModal } from '@components/NewModal/NewModal';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmEncodingYoY } from './useConfirmEncodingYoY';
/** Encoding Confirmation modal, for v3 YoY purpose only */
export const ConfirmEncodingYoYModal = ({ open, onClose, farmSeason, farm, farmerName, carbonModel, }) => {
    var _a;
    const { t } = useTranslation();
    const { confirmEncodingYoY, isLoading } = useConfirmEncodingYoY({
        carbonResultModel: carbonModel,
        onSuccess: onClose,
    });
    const isBaseline = farmSeason.baseline_farm_season_id === null;
    return (_jsx(NewModal, { open: open, onClose: onClose, onConfirm: () => confirmEncodingYoY(farmSeason.id, isBaseline), header: _jsx(NewModal.Header, { children: _jsx(NewModal.Title, { children: t('farmers.results.modal-confirm-yoy-encoding.title') }) }), body: _jsx(NewModal.Body, { children: _jsx(Typography, { children: _jsx(Trans, { i18nKey: 'farmers.results.modal-confirm-yoy-encoding.description', values: {
                        farmName: farm.name,
                        farmerName: farmerName,
                        harvestYear: (_a = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _a === void 0 ? void 0 : _a.harvest_year,
                    } }) }) }), footer: _jsxs(NewModal.Footer, { children: [_jsx(NewModal.Cancel, { children: t('farmers.results.modal-confirm-yoy-encoding.cancel') }), _jsx(NewModal.Confirm, Object.assign({ disabled: isLoading }, { children: isLoading ? (_jsx(CircularProgress, { size: 15, sx: { color: 'white' } })) : (t('farmers.results.modal-confirm-yoy-encoding.confirm')) }))] }) }));
};
