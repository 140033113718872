import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { api } from '@services/api.service';
import { authReducer } from '@features/auth/authSlice';
import { permissionReducer } from '@features/permission/permissionSlice';
import { dashboardReducer } from '@features/dashboard/dashboardSlice';
import { farmersListReducer } from '@features/farmer/listFarmers/farmersList.slice';
import { alarmingDataReducer } from '@features/alarmingData/alarmingData.slice';
import { authApi } from '@services';
const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    permissions: permissionReducer,
    dashboard: dashboardReducer,
    farmersList: farmersListReducer,
    alarmingData: alarmingDataReducer,
});
const rootReducer = (state, action) => {
    if (authApi.endpoints.logout.matchFulfilled(action)) {
        state = undefined; // Reset the entire state on logout
    }
    return appReducer(state, action);
};
export const setupStore = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
        preloadedState,
    });
};
export const store = setupStore();
