import { selectFarmersListCohortId, selectFarmersListContactId, selectFarmersListPage, selectFarmersListRegionCode, selectFarmersListSearch, selectFarmersListStatus, } from '@features/farmer/listFarmers/farmersList.slice';
import { useAppSelector } from '@hooks/redux';
import { usePermission } from '@hooks/usePermission';
import { ApiToggle } from '@services';
import { useGetFarmersQuery } from '@services/farmer.api';
import { skipToken } from '@reduxjs/toolkit/query';
export const useGetFarmers = ({ shouldFilter = false } = {}) => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const cohortId = useAppSelector(selectFarmersListCohortId);
    const regionCode = useAppSelector(selectFarmersListRegionCode);
    const contactId = useAppSelector(selectFarmersListContactId);
    const status = useAppSelector(selectFarmersListStatus);
    const search = useAppSelector(selectFarmersListSearch);
    const page = useAppSelector(selectFarmersListPage);
    const hasPage = !!page || page === 0;
    const trimmedSearch = search ? encodeURIComponent(search === null || search === void 0 ? void 0 : search.trim()) : '';
    const queryParams = {
        cohortId: cohortId,
        regionCode: regionCode,
        contactId: contactId,
        status: status,
        search: trimmedSearch,
        page: hasPage ? page : undefined,
    };
    // Serialize the object into a query string
    const computedQueryString = Object.entries(queryParams)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    const queryString = computedQueryString ? `?${computedQueryString}` : '';
    const params = canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers
        ? Object.assign({ toggle: ApiToggle.PARTNER }, (shouldFilter && { queryString })) : canSeeAssociateFarmers || canSeeAssociateAssignedFarmers
        ? Object.assign({ toggle: ApiToggle.ASSOCIATE }, (shouldFilter && { queryString })) : skipToken;
    const { data, isLoading, isFetching, isError } = useGetFarmersQuery(params);
    return {
        data: data === null || data === void 0 ? void 0 : data.farmers,
        count: data === null || data === void 0 ? void 0 : data.results,
        hasMore: data === null || data === void 0 ? void 0 : data.hasMore,
        isLoading,
        isFetching,
        isError,
    };
};
