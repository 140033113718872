import { api } from './api.service';
export const seasonApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSeasonsForPartner: builder.query({
            query: () => ({
                url: `/private/seasons`,
                method: 'GET',
            }),
        }),
        getFarmerSeasons: builder.query({
            query: (id) => ({
                url: `/private/seasons/farm/${id}`,
                method: 'GET',
            }),
        }),
        confirmEncodingV2: builder.mutation({
            query: ({ farmId, farmSeasonId }) => ({
                url: `${process.env.FARMER_PORTAL_API}/v1/farm/${farmId}/season/${farmSeasonId}/validate`,
                method: 'PUT',
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
        confirmEncodingV3: builder.mutation({
            query: (farmSeasonId) => ({
                url: `${process.env.FARMER_PORTAL_API}/v3/farm-seasons/${farmSeasonId}/confirm-encoding`,
                method: 'POST',
            }),
            invalidatesTags: ['Farmer', 'Farmers'],
        }),
    }),
});
export const { useGetFarmerSeasonsQuery, useGetSeasonsForPartnerQuery, useConfirmEncodingV2Mutation, useConfirmEncodingV3Mutation, } = seasonApi;
