import { usePermission } from '@hooks/usePermission';
import { FARMER_STATUS } from '@utils/constants';
export const associateStatuses = [
    { id: 1, name: `statuses.${FARMER_STATUS.CREATED}`, type: FARMER_STATUS.CREATED },
    { id: 3, name: `statuses.${FARMER_STATUS.ENROLLED}`, type: FARMER_STATUS.ENROLLED },
    {
        id: 5,
        name: `statuses.${FARMER_STATUS.INITIAL_SETUP_IN_PROGRESS}`,
        type: FARMER_STATUS.INITIAL_SETUP_IN_PROGRESS,
    },
    {
        id: 6,
        name: `statuses.${FARMER_STATUS.WAITING_FOR_AGRONOMIST_MEETING}`,
        type: FARMER_STATUS.WAITING_FOR_AGRONOMIST_MEETING,
    },
    {
        id: 7,
        name: `statuses.${FARMER_STATUS.AGRONOMIST_DATA_INPUT}`,
        type: FARMER_STATUS.AGRONOMIST_DATA_INPUT,
    },
    {
        id: 8,
        name: `statuses.${FARMER_STATUS.DATA_VALIDATION}`,
        type: FARMER_STATUS.DATA_VALIDATION,
    },
    {
        id: 9,
        name: `statuses.${FARMER_STATUS.RESULTS_UNDER_REVIEW}`,
        type: FARMER_STATUS.RESULTS_UNDER_REVIEW,
    },
    {
        id: 10,
        name: `statuses.${FARMER_STATUS.RESULTS_AVAILABLE}`,
        type: FARMER_STATUS.RESULTS_AVAILABLE,
    },
];
export const partnerStatuses = [
    { id: 1, name: `statuses.${FARMER_STATUS.PENDING}`, type: FARMER_STATUS.PENDING },
    { id: 2, name: `statuses.${FARMER_STATUS.INVITATION_REFUSED}`, type: FARMER_STATUS.INVITATION_REFUSED },
    { id: 3, name: `statuses.${FARMER_STATUS.CREATED}`, type: FARMER_STATUS.CREATED },
    { id: 5, name: `statuses.${FARMER_STATUS.ENROLLED}`, type: FARMER_STATUS.ENROLLED },
    {
        id: 7,
        name: `statuses.${FARMER_STATUS.INITIAL_SETUP_IN_PROGRESS}`,
        type: FARMER_STATUS.INITIAL_SETUP_IN_PROGRESS,
    },
    {
        id: 8,
        name: `statuses.${FARMER_STATUS.WAITING_FOR_AGRONOMIST_MEETING}`,
        type: FARMER_STATUS.WAITING_FOR_AGRONOMIST_MEETING,
    },
    {
        id: 9,
        name: `statuses.${FARMER_STATUS.AGRONOMIST_DATA_INPUT}`,
        type: FARMER_STATUS.AGRONOMIST_DATA_INPUT,
    },
    {
        id: 10,
        name: `statuses.${FARMER_STATUS.DATA_VALIDATION}`,
        type: FARMER_STATUS.DATA_VALIDATION,
    },
    {
        id: 11,
        name: `statuses.${FARMER_STATUS.RESULTS_UNDER_REVIEW}`,
        type: FARMER_STATUS.RESULTS_UNDER_REVIEW,
    },
    {
        id: 12,
        name: `statuses.${FARMER_STATUS.RESULTS_AVAILABLE}`,
        type: FARMER_STATUS.RESULTS_AVAILABLE,
    },
];
export const useGetAvailableFarmerStatuses = () => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const isPartner = canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers;
    const isAssociate = canSeeAssociateFarmers || canSeeAssociateAssignedFarmers;
    if (isPartner)
        return partnerStatuses;
    if (isAssociate)
        return associateStatuses;
    return [];
};
