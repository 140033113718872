import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        container: {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '8px',
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 0,
            marginTop: -theme.spacing(2),
            marginBottom: theme.spacing(3),
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
            fontFamily: theme.typography.fontFamily,
            width: '100%',
        },
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        box: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            padding: '8px',
        },
        icon: { fill: theme.palette.common.white, fontSize: 'medium' },
    };
});
